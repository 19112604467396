import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="p-16 flex flex-col content-center items-center">
      <h2 className="text-4xl font-bold text-orange-900 uppercase tracking-tighter">
        404 - Not found
      </h2>
      <article className="mt-8 shadow-2xl max-w-2xl mx-auto">
        <div className="border-4 border-gray-900 bg-white">
          <div className="p-6">
            <section>
              <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
            </section>
          </div>
        </div>
      </article>
    </div>
  </Layout>
)

export default NotFoundPage
